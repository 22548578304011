.cart-wrapper{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  .cart-wrapper-item{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 0 3.5rem 2rem;
  }
  .btn-wrapper {
    .buy-now-button {
      padding: 1.3rem 6.5rem;
    }
  }
}

.delete-cart-item-modal {
  max-width: 90%;
  margin: 10rem auto;
  button {
    margin-right: 2rem;
    padding: 1rem 2rem;
  }
}

.cart-item{
  position: relative;
  text-align: center;
  cursor: pointer;
  .cart-product-img{
    background-image: linear-gradient(34deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1) 58%, rgba(6, 6, 6, 0.1) 96%);
    width: 100%;
  }
  .delete-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
  h4, h3{
    margin-top: .5rem;
    margin-bottom: 0;
  }
  h5{
    margin: 1.5rem .5rem .5rem;
    display: inline-block;
  }
  .quantity-wrapper {
    display: inline-block;
    span {
      margin: .4rem;
    }
    span.quantity {
      border: 1px solid $grey-e2;
      padding: .5rem;
      display: inline-block;
    }
    .glyphicon-plus, .glyphicon-minus {
      font-size: 1.2rem;
      color: $primary;
      cursor: pointer;
    }
  }
}