.information-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  display: none;
  background-color: $black-33-38;
  justify-content: center;
  align-items: center;
  &.open {
    display: flex;
  }
  .information-modal-body {
    background-color: white;
    width: 54rem;
    max-width: 90%;
    padding: 4rem;
    border-radius: 6px;
    &.size-guide-modal {
      width: 80%;
    }
    img {
      margin-bottom: 2rem;
    }
    .custom-btn {
      float: right;
      padding: 1rem 3rem;
      margin: 1rem;
    }
  }
}
