.side-drawer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0,0,0, .38);
  visibility: hidden;
  &.open {
    visibility: visible;
    .content-wrapper {
      transform: translateX(0);
    }
  }
  .content-wrapper {
    width: 30%;
    height: 100%;
    background-color: white;
    overflow-y: auto;
    position: absolute;
    right: 0;
    transform: translateX(100%);
    transition: all 300ms;
    padding-top: 6rem;
    .back-icon {
      position: absolute;
      left: 2rem;
      font-size: 20px;
      color: $primary;
      cursor: pointer;
    }
    h4.title {
      margin: 0 1rem 3rem 7rem;
      display: inline-block;
      @include font($black-33-87, "PlayfairDisplay-Bold", 1.6rem, bold)
    }
  }
}