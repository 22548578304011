@media (max-width: 768px) {
  // add bottom padding wherever sticky buttons are visible
  .product-details,
  .shipping-address,
  .custom-container,
  .signup {
    ~ .footer {
      padding-bottom: 9rem;
    }
  }
  .footer {
    .footer-wrapper {
      .left {
        .logos-wrapper {
          margin-bottom: 3rem;
          img {
            height: 3.5rem;
            margin-right: 2rem;
          }
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .footer {
    padding: 0 1rem;
    .footer-wrapper {
      flex-direction: column;
      .left {
        ul {
          li {
            display: block;
            margin: 1rem 0 2rem;
          }
        }
      }
      .right {
        h6 {
          text-align: left;
        }
      }
    }
  }
}
