@media (max-width: $navbar-collapse-breakpoint) {
  .custom-navbar-menu {
    display: block;
    padding: 0;
    border: none;
    .tag-names-wrapper {
      width: 100%;
      .tag-column {
        width: 100%;
        display: block;
      }
      .tag-item {
        width: 100%;
        display: block;
        a {
          padding-left: 8rem;
        }
        .tag-image {
          width: 7rem;
        }
      }
    }
    .extra-links-wrapper {
      width: 100%;
      a {
        padding-left: 8rem;
      }
    }
    .menu-stone-shapes {
      width: 100%;
      .diamond-shapes-list-wrapper {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
