.catalogs-wrapper {
  .catalogs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin: 3.5rem 0;
    text-align: center;
    .catalog-item {
      margin: 1rem 0;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
      }
      h5 {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }
  }
}