@media (max-width: 768px) {
  .catalogs-wrapper {
    .catalogs {
      gap: 1rem;
      .catalog-item {
        h5 {
          margin-top: 1rem;
          margin-bottom: 0;
        }
      }
    }
  }
}