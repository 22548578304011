.full-image-slider {
  margin: 8rem 0;
  img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .full-image-slider {
    margin: 4rem 0;
  }
}