@media (max-width: 768px) {
  .sticky-mobile-bottom-buttons {
    // These buttons will be normal in Desktop/tablet and sticky in mobile
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem 1.6rem;
    text-align: center;
    background-color: white;
    box-shadow: 0 0 23px 7px rgba(0, 0, 0, 0.11);
    z-index: 98;
  }
}