@media (max-width:600px){
  .diamond-shapes-list-wrapper {
    grid-template-columns: repeat(3, 1fr);
    a {
      img {
        height: 4.8rem;
        margin-bottom: 3rem;
      }
    }
  }
}