.favourite-wrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  text-align: center;
  padding-top: 0.9rem;
  border-radius: 50%;
  cursor: pointer;
  &.bordered {
    border: 1px solid $black-33-38;
    top: 2rem;
    right: 2rem;
  }
  .glyphicon {
    font-size: 2rem;
  }
  .glyphicon-heart {
    color: $primary;
  }
  .glyphicon-heart-empty {
    color: $black-33-38;
  }
}

.favourites-tooltip {
  .tooltip-inner {
    padding: 0.5rem 1rem;
    max-width: 500px;
  }
}
