.secondary-navbar {
  background-color: $grey-e5;
  .content-wrapper {
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    height: 44px;
    .email-text {
      @include font($black-33-54, "Muli-Regular", 1.3rem, normal);
      flex: 1;
    }
    a {
      @include font($black-33, "Muli-Regular", 1.2rem, normal);
    }
    h6 {
      margin: 0;
      margin-left: 3rem;
      @include font($black-33-54, "Muli-Regular", 1.3rem, normal);
      span {
        @include font($black-33, "Muli-ExtraBold", 1.3rem, bold);
      }
    }
  }
}
