.signin-ribbon {
  background-color: $primary;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 96;
  padding: 1.3rem;
  box-shadow: 0 -7px 29px 0 rgba(0, 0, 0, 0.15);
  span {
    margin-right: 4rem;
    @include font($black-33, "Muli-Regular", 1.6rem, normal);
  }
  .custom-btn {
    margin: 0 0.5rem;
    padding: 1rem 1.5rem;
    &.custom-inverse {
      background-color: transparent;
      &:hover {
        background-color: $black-33;
      }
    }
  }
}

@media (max-width: 768px) {
  .signin-ribbon {
    // bottom: 8.7rem;
    span {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100%;
      text-align: center;
    }
  }
}
