.markups-wrapper {
  margin-bottom: 5rem;
  .control-label {
    font-size: 1.5rem;
  }
  .radio-inline {
    display: block;
    margin-bottom: 0.7rem;
    span {
      color: #777;
    }
  }
  .radio-inline + .radio-inline {
    margin-left: 0;
  }
  .custom-btn {
    padding: 1.3rem 3rem;
    margin-right: 2rem;
    &[type="submit"] {
      border-width: 3px;
      padding: 1.3rem 3.5rem;
    }
  }
  h3 {
    margin-bottom: 3rem;
    font-size: 1.7rem;
  }
  .markup-suggestions-wrapper {
    margin-bottom: 3rem;
    margin-top: -1.5rem;
    .suggestions-pill {
      @include font($black-33-54, "Muli-Regular", 1.3rem, normal);
      cursor: pointer;
      border: 1px solid $primary;
      padding: 0.5rem 1rem;
      border-radius: 20px;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
      display: inline-block;
      width: 6rem;
      text-align: center;
      &.active {
        color: white;
        background-color: $primary;
      }
    }
  }
}
