.order-details {
  .order-details-content {
    .content-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 1.8rem;
    }
    h2 {
      @extend .subtitle-desktop;
      text-transform: uppercase;
      margin-top: 3.2rem;
      margin-bottom: 1.6rem;
    }
    h3 {
      @extend .product-price;
      margin-top: 0;
      margin-bottom: 1.6rem;
    }
    h4 {
      @extend .product-card-title;
      margin-top: 1.5rem;
      margin-bottom: 0.4rem;
    }
    h5 {
      @extend .product-code;
      text-transform: capitalize;
      margin: 0 0.5rem 0.6rem;
    }
    h6 {
      @extend .dark-desktop;
      letter-spacing: 0.69px;
      word-wrap: break-word;
      margin: 0.6rem 0.5rem 0;
    }

    /* =================
          ORDER SUMMARY
      ================= */

    .order-summary {
      background-color: $grey-f1;
      padding: 1.6rem 2rem 0;
      margin: 3rem 0;
      border-radius: 4px;
      border: 1px solid $black-33-20;
      display: flex;
      flex-wrap: wrap;
      .order-summary-item {
        width: 16.66%;
        margin-bottom: 1.5rem;
      }
    }

    /* =================
          CUSTOMER INFO
      ================= */

    .customer-info {
      margin: 3rem 0;
      .info-item {
        margin-bottom: 2rem;
      }
    }

    /* =================
          FULFILLMENT DETAILS
      ================= */
    
    .fulfillment-details {
      margin: 3rem 0;
      .timeline-wrapper {
        display: flex;
        margin-top: 3rem;
        .timeline-item {
          flex: 1;
          text-align: center;
          position: relative;
          h4 {
            text-transform: uppercase;
          }
          h5 {
            margin-bottom: 0.4rem;
          }
          &::before {
            // line
            content: '';
            width: 100%;
            height: 2px;
            background-color: $grey-f1;
            position: absolute;
            top:0;
            left: -50%;
          }
          &:first-child::before {
            display: none;
          }
          &::after {
            // circle
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $grey-f1;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -3px);
            z-index: 1;
          }
          &.green::before, &.green::after {
            background-color: $green;
          }
        }
      }
    }

    /* =================
          PRODUCTS BOUGHT
      ================= */
      
    .products-bought {
      margin: 3rem 0;
      .panel {
        margin-bottom: 4rem;
        .panel-heading {
          background-color: white;
          padding: 1rem 2rem;
        }
        .panel-title a{
          display: flex;
          align-items: center;
          .title-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            img {
              width: 8rem;
            }
            h4 {
              width: 50%;
              margin: 0 2rem;
              line-height: 2.1rem;
              text-transform: uppercase;
            }
          }
          .glyphicon {
            padding: 1rem;
            background-color: $black-10;
            border-radius: 50%;
          }
        }
        .panel-body {
          padding: 0 2rem 3rem;
        }
      }
      .content-wrapper + .content-wrapper {
        border-top: 1px solid $grey-f1;
        margin-top: 1.8rem;
        padding-top: 1.8rem;
      }
    }
  }
}