// Include PLayfairDisplay font
@include font-face(PlayfairDisplay-Bold, "../fonts/playfair/PlayfairDisplay-Bold", bold, normal, ttf);

// Muli font
@include font-face(Muli-Regular, "../fonts/muli/Muli-Regular", 500, normal, ttf);
@include font-face(Muli-Bold, "../fonts/muli/Muli-Bold", bold, normal, ttf);
@include font-face(Muli-ExtraBold, "../fonts/muli/Muli-ExtraBold", bold, normal, ttf);
@include font-face(Muli-Black, "../fonts/muli/Muli-Black", 500, normal, ttf);

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// SET THE BASE HTML REM, MAKING 1REM=10PX
html {
  font-size: 10px;
}

a:hover,
a:focus {
  text-decoration: none;
}

// FOR BORDER AROUND SELECT BOX IN ERROR STATE
.form-group.has-error > div > div {
  border-color: #cc0000;
  box-shadow: none;
}

// IMAGE UPLOAD STYLE.
.upload-image-wrapper {
  label {
    h2 {
    }
    span {
      display: inline-block;
      padding: 0.4rem 1.9rem 1rem;
      border: 1px solid #cccccc;
      font-size: 3rem;
      background-color: #ffffff;
      color: #ccc;
      cursor: pointer;
    }
  }
  input {
  }
}

// UPLOADED IMAGE STYLE
.img-preview-wrapper {
  .uploaded-img-wrapper {
    width: 12rem;
    height: 14rem;
    position: relative;
    margin-right: 2rem;
    display: inline-block;
    img {
      object-fit: cover;
      height: inherit;
      width: 100%;
    }
    .option-card-extension {
      font-size: 2.2rem;
      color: $grey-1;
      border: 4px solid $grey-dd;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
    }
    .glyphicon {
      position: absolute;
      top: -7px;
      right: -10px;
      /* color: #fff; */
      /* z-index: 2; */
      background-color: #fff;
      font-size: 3rem;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .progress {
    width: 12rem;
    margin-top: 1rem;
    height: 1rem;
  }
}

// DISABLE TEXTAREA RESIZING
textarea {
  resize: none;
}

// For Form Input Styling
.form-group {
  margin-bottom: 2.4rem;
  .form-control {
    height: 43px;
    // box-sizing: content-box;
    padding: 10px 15px;
    border: 1px solid $grey-d8;
    border-radius: 2px;
    box-shadow: none;
    line-height: 0;
  }
  .form-control::placeholder {
    @include placeholder();
  }
  .form-control::-webkit-input-placeholder {
    @include placeholder();
  }
  .form-control::-moz-placeholder {
    @include placeholder();
  }
  .form-control:-moz-placeholder {
    @include placeholder();
  }
  .form-control:-ms-input-placeholder {
    @include placeholder();
  }
  textarea.form-control {
    // height: auto;
  }
}

// For Form Label
form {
  .control-label {
    @include font($black-33-54, "Muli-Regular", 1.2rem, 400);
  }
}

// For error borders
.has-error .form-control {
  border-color: $red;
  box-shadow: none;
}

// For error text
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: $red;
}

// Common Text Style
.h2-desktop {
  @include font($black-33, "PlayfairDisplay-Bold", 2.2rem, bold);
}
.h3-desktop {
  @include font($black-33-87, "PlayfairDisplay-Bold", 1.6rem, bold);
}
.product-card-title {
  @include font($black-33, "Muli-Regular", 1.4rem, 400);
}
.product-price {
  @include font($black-33, "Muli-ExtraBold", 1.8rem, bold);
}
.product-name {
  @include font($black-33, "PlayfairDisplay-Bold", 1.6rem, bold);
}
.product-code {
  @include font($black-33-54, "Muli-Regular", 1.2rem, normal);
  text-transform: uppercase;
}
.product-desc {
  @include font($black-33-54, "Muli-Regular", 1.2rem, normal);
  line-height: 2.1rem;
}
.h1 {
  @include font($black-33, "PlayfairDisplay-Bold", 2.4rem, bold);
}
.dark-desktop {
  @include font($black-33, "Muli-Bold", 1.3rem, bold);
}
.light-desktop {
  @include font($black-33-54, "Muli-Bold", 1.3rem, bold);
}
.title-desktop {
  @include font($black-33, "Muli-Bold", 2rem, bold);
}
.subtitle-desktop {
  @include font($black-33-54, "Muli-Regular", 1.6rem, normal);
}
.paragraph-desktop {
  @include font($black-33-87, "Muli-Regular", 1.4rem, normal);
  line-height: 2.2rem;
}
.menu-desktop {
  @include font($black-33, "Muli-Bold", 1.4rem, bold);
  letter-spacing: -0.5px;
}

// custom grid
.assign-flex {
  display: flex;
  flex-wrap: wrap;

  $devices: (
    "dsk": "min-width: 991px",
    "tab": "max-width: 990px",
    "mob": "max-width: 768px",
  );

  $columns: (1, 2, 3, 4);

  @each $device, $query in $devices {
    @each $column in $columns {
      @media (#{$query}) {
        .#{$device}-#{$column} {
          width: (100% /#{$column});
        }
      }
    }
  }
}

// video
video {
  width: 100%;
  display: block;
}

// Styles for dots
.white-color {
  background-color: #e4e2e2;
  &.active {
    border: 2px solid #979797;
  }
}
.rose-color {
  background-color: #ffdee2;
  &.active {
    border: 2px solid #ff9ba7;
  }
}
.yellow-color {
  background-color: #ffe1ac;
  &.active {
    border: 2px solid #ffca73;
  }
}

// pagination
.pagination-wrapper {
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 3rem;
  padding-left: 0;
  button {
    background-color: white;
    &:hover,
    &:active,
    &:focus {
      background-color: $primary;
      color: white;
    }
  }
  li {
    display: inline-block;
    margin: 0 0.5rem;
    padding: 1rem 1rem;
    border-radius: 4px;
    @include font($black-33-87, "Muli-ExtraBold", 1.6rem, bold);
    a {
      color: inherit;
      .glyphicon {
        font-size: 0.9rem;
        top: 0;
      }
    }
    &.active {
      background-color: $primary;
      color: $white;
    }
    &.prev,
    &.next {
      border: 0.5px solid $grey-dd;
      border-radius: 4px;
      padding: 1rem 2rem;
      font-size: 1.4rem;
    }
    &.prev {
      margin-right: 2rem;
    }
    &.next {
      margin-left: 2rem;
    }
  }
}

.sticky-bottom-buttons {
  // for sidebar. These buttons will sticky in all screens
  position: sticky;
  z-index: 98;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem 1rem;
  text-align: center;
  background-color: white;
  box-shadow: 0 -1px 11px 0 rgba(182, 182, 182, 0.5);
}

.checkbox {
  // hide default
  input[type="checkbox"] {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;
  }
  // show custom
  .checkmark {
    height: 2rem;
    width: 2rem;
    position: absolute;
    left: 0;
    border: 2px solid $grey-b1-54;
    border-radius: 2px;
  }
  // tickmark inside checkbox
  input[type="checkbox"] ~ .checkmark::after {
    content: "";
    position: absolute;
    width: 0.7rem;
    height: 1.4rem;
    left: 0.4rem;
    bottom: 0.2rem;
    border: solid white;
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
    display: none;
  }

  // show tickmark when checked
  input[type="checkbox"]:checked ~ .checkmark::after {
    display: block;
  }
  // change background when checked
  input[type="checkbox"]:checked ~ .checkmark {
    background-color: $primary;
  }
}

.radio-buttons-wrapper {
  label {
    padding-left: 2.7rem;
  }
  // hide default
  input[type="radio"] {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;
  }
  // show custom
  .checkmark {
    height: 2rem;
    width: 2rem;
    position: absolute;
    left: 0;
    border: 2px solid $grey-b1-54;
    border-radius: 50%;
  }
  // circle inside radio button
  input[type="radio"] ~ .checkmark::after {
    content: "";
    position: absolute;
    left: 0.4rem;
    top: 0.4rem;
    border-radius: 50%;
    height: 0.8rem;
    width: 0.8rem;
    background-color: white;
    display: none;
  }
  // show circle when checked
  input[type="radio"]:checked ~ .checkmark::after {
    display: block;
  }
  // change background when checked
  input[type="radio"]:checked ~ .checkmark {
    background-color: $primary;
  }
}

.custom-toastify {
  text-align: center;
}

.custom-select-container {
  .custom-select__control {
    min-height: 43px;
    border-radius: 2px;
    &:hover {
      border-color: $primary;
    }
    &--is-focused {
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary;
    }
    .custom-select__value-container {
      @include font($black-33, "Muli-Regular", 1.4rem, normal);
      padding-left: 5%;
    }
  }
  .custom-select__menu {
    box-shadow: 0 0 23px 3px rgba(0, 0, 0, 0.11);
    .custom-select__menu-list {
      padding: 0;
      max-height: 70vh;
      .custom-select__option {
        @include font($black-33, "Muli-Regular", 1.2rem, normal);
        position: relative;
        padding: 1.6rem 5%;
        &--is-focused {
          background-color: $primary-38;
        }
        // &::after {
        //   content: '';
        //   position: absolute;
        //   bottom: 0.5px;
        //   left: 3%;
        //   width: 94%;
        //   height: 0.5px;
        //   background-color: $grey-d4;
        // }
        // &:last-child::after {
        //   display: none;
        // }
      }
    }
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .pagination-wrapper {
    li {
      display: none;
      &.prev,
      &.next {
        display: inline-block;
      }
    }
  }
}
