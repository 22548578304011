/* =================
      BANNER
  ================= */

.slick-slider.banner {
  .slick-slide {
    .background-wrapper {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      padding-top: 33.3333%;
      position: relative;
      .slide-content {
        width: 40%;
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        h3 {
          @include font(#333333, "PlayfairDisplay-Bold", 3rem, bold);
        }
        h4 {
          @include font(#333333, "Muli-Regular", 1.6rem, normal);
          opacity: 0.54;
          line-height: 2.4rem;
        }
        .banner-button {
          margin-top: 20px;
        }
      }
    }
  }

  .slick-dots {
    bottom: 10px;
  }

  .slick-dots li button:before {
    font-size: 12px;
  }
}

/* =================
      SHOP
  ================= */

.shop-banners {
  h4 {
    @include font(#333333, "Muli-Bold", 1.3rem, bold);
    opacity: 0.54;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    text-align: center;
  }
  h3 {
    @include font(#333333, "PlayfairDisplay-Bold", 2.4rem, bold);
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }
  .assign-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 550px) {
      grid-template-columns: 1fr;
    }
    padding: 6rem 0;
    grid-gap: 3rem;
  }
  .shop-banner-item {
    text-align: center;
    h5 {
      @include font(#333333, "Muli-Bold", 2rem, bold);
      text-transform: uppercase;
      margin-top: 4rem;
    }
    h6 {
      @include font(#333333, "Muli-Regular", 1.6rem, normal);
      opacity: 0.54;
      margin-bottom: 3rem;
    }
    img {
      width: 100%;
    }
  }
}

/* =================
      NEW ARRIVALS
  ================= */

.new-arrivals {
  text-align: center;
  h3 {
    @include font(#333333, "Muli-Bold", 1.3rem, bold);
    opacity: 0.54;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }
  h2 {
    @include font(#333333, "PlayfairDisplay-Bold", 2.4rem, bold);
    margin-top: 0;
    margin-bottom: 0;
    margin-bottom: 6rem;
  }
  .grid-4-col {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 3rem;
  }
  .all-products-button {
    margin-top: 2rem;
    margin-bottom: 6rem;
  }
}

/*=================
      DIAMOND SHAPES
  ================= */

.diamond-shapes {
  .container {
    background-color: #f8f2ed;
    border-radius: 13px;
    text-align: center;
    padding: 5rem 3rem 3rem 3rem;
    border: 2px solid $primary;
  }
  h3 {
    @include font(#333333, "PlayfairDisplay-Bold", 2.2rem, bold);
  }
  h4 {
    @include font(#333333, "Muli-Regular", 1.6rem, normal);
    opacity: 0.54;
  }
}

/* =================
      FOOTER BANNER
  ================= */
.footer-banner {
  img {
    width: 100%;
  }
  margin-bottom: 8rem;
}
