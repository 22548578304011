.order-list {
  .glyphicon-arrow-left {
    color: $primary;
    margin-right: 1.4rem;
  }
  h2{
    margin: 3.2rem 0;
  }
  .order-item {
    border: 1px solid $grey-e2;
    margin: 2rem 0;
    padding: 1.5rem 2.2rem;
    border-radius: 5px;
    .order-item-header {
      border-bottom: 2px solid $grey-f1;
      padding: 0 .4rem;
      h5 {
        @extend .product-code;
        text-transform: capitalize;
        margin: 0;
      }
      h6 {
        @extend .dark-desktop;
        letter-spacing: 0.69px;
        margin-top: 0.4rem;
        margin-bottom: 1.2rem;
      }
    }
    .order-item-body {
      padding-top: 1.7rem;
      .images-wrapper {
        display: flex;
        flex-wrap: wrap;
        img, .more-images {
          width: 8.5rem;
          height: 8.5rem;
          border-radius: 2px;
          margin-right: 1rem;
          margin-bottom: 1rem;
          background-image: linear-gradient(34deg, rgba(0,0,0,0.1), rgba(255,255,255,0.1) 58%, rgba(6,6,6,0.1) 96%);
        }
        .img {
          object-fit: cover;
        }
        .more-images {
          @extend .product-code;
          text-transform: capitalize;
          background-color: $primary-38;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .custom-btn {
        padding: 1.3rem;
      }
    }
  }
}