.contact {
  padding: 2rem 0 5rem 0;
  p {
    @extend .paragraph-desktop;
    margin: 3rem 0;
    span {
      font-weight: bold;
    }
  }
  .custom-btn {
    padding: 1.3rem 3rem;
  }
}
