.product-banner {
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primary-38;
  background-size: cover;
  h1 {
    margin: 0;
    text-transform: capitalize;
  }
  h2 {
    margin-top: 1.2rem;
    text-transform: capitalize;
  }
}

.custom-container {
  max-width: 1680px;
  padding: 4rem 3rem;
  // margin: 0 auto;
  .content-wrapper {
    display: flex;
    .filter-wrapper {
      flex: 0.18;
      // border: 0.1px solid #f5f5f5;
    }
    .main {
      flex: 0.82;
      align-self: flex-start;
      // border: 0.1px solid #f5f5f5;
      .product-list {
        display: grid;
        // grid-template-columns: repeat(auto-fill, 255px);
        grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
        gap: 25px;
        justify-content: end;
      }
      .empty-state-wrapper img {
        width: 35rem;
      }
      padding-left: 30px;
    }
  }
}

.top-wrapper {
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  .breadcrumb-wrapper {
    flex: 0.25;
  }
  p {
    width: 100%;
    text-align: right;
    color: $black-33;
    margin-top: 1rem;
  }
  .sort-wrapper {
    flex: 0.75;
    padding-left: 30px;
    display: flex;
    .left {
      flex: 0.8;
      .filter-summary {
        display: inline-block;
        border: 1px solid $grey-dd;
        background-color: $grey-f1;
        margin-right: 1rem;
        margin-bottom: 1rem;
        padding: 0.8rem 1rem;
        border-radius: 2rem;
        .glyphicon {
          margin-left: 0.5rem;
          top: 2px;
          cursor: pointer;
        }
      }
    }
    .right {
      flex: 0.2;
      .custom-select-container {
        .custom-select__control {
          background-color: $primary-12;
          border: none;
          border-radius: 6px;
          min-height: 48px;
        }
      }
    }
  }
}
