.search-form{
  margin: 2.4rem 0 4rem;
  form{
    display: flex;
    justify-content: center;
    .input-wrapper{
      width: 64%;
      position: relative;
      .glyphicon{
        position: absolute;
        font-size: 2rem;
        top: 50%;
        transform: translateY(-50%);
        left: 3rem;
      }
      .clear{
        position: absolute;
        right: 3rem;
        top: 50%;
        transform: translateY(-50%);
        @include font($primary, "Muli-ExtraBold", 1.2rem, bold);
        text-transform: uppercase;
        cursor: pointer;
      }
      input{
        padding: 2rem 4rem 2rem 7rem;
        height: 7rem;
        @extend .product-card-title;
        &:focus{
          box-shadow: none;
          border: 1px solid $grey-d8;
        }
      }
      .escape-wrapper{
        position: absolute;
        right: -4rem;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        cursor: pointer;
        opacity: 0.54;
        h6{
          margin-bottom: 0;
          font-family: "Muli-Bold", Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .search-form{
    form{
      .input-wrapper{
        width: 90vw;
        .glyphicon{
          left: 1.5rem;
          font-size: 1.6rem;
        }
        .clear{
          right: 1.5rem;
        }
        input{
          height: 5rem;
          padding: 1rem 8.4rem 1rem 4.5rem;
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .search-form{
    form{
      .input-wrapper{
        .escape-wrapper{
          display: none;
        }
      }
    }
  }
}