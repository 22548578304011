.profile {
  .profile-content {
    width: 52rem;
    max-width: 100%;
    margin: 2.5rem auto;

    .account-details {
      text-align: center;
      .profile-image-wrapper {
        position: relative;
        display: inline-block;
        .profile-image {
          width: 11rem;
          height: 11rem;
          object-fit: contain;
          border: 1px solid $grey-e2;
          border-radius: 50%;
          position: relative;

        }
        .change-photo-wrapper {
          background-color: $secondary;
          position: absolute;
          right: 0;
          bottom: 0;
          height: 3.6rem;
          width: 3.6rem;
          padding: 0.6rem;
          border-radius: 50%;
          cursor: pointer;
          .change-photo {
            width: 1.6rem;
          }
        }

      }
    }

    .profile-item {
      display: flex;
      border: 1px solid $grey-e2;
      border-radius: 4px;
      padding: 1.5rem;
      margin: 1.5rem;
      align-items: center;
      cursor: pointer;
      img {
        width: 5rem;
      }
      h6 {
        margin-left: 1rem;
      }
    }
  }
}