.diamond-shapes-list-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 4rem;
  margin-bottom: 4rem;
  a {
    border: none;
    margin-bottom: 0;
    padding-bottom: 20px;
    background-color: transparent;
    img {
      height: 6rem;
    }
  }
}
