.custom-navbar-menu {
  cursor: default;
  border-top: 1px solid $grey-f1;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  // left side
  .tag-names-wrapper {
    width: 80%;
    .tag-column {
      display: inline-block;
      width: 33%;
      vertical-align: top;
    }
    .tag-item {
      // width: 25%;
      // display: inline-block;
      // vertical-align: middle;
      position: relative;
      a {
        @include font($black-33-87, "Muli-Regular", 1.2rem, normal);
        display: inline-block;
        padding: 1.2rem 1rem;
        padding-left: 8rem;
      }
      .tag-image {
        width: 7rem;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0.5rem;
      }
    }
  }

  // right side
  .extra-links-wrapper {
    width: 20%;
    a {
      @extend .dark-desktop;
      display: inline-block;
      padding: 1.2rem 1rem;
      padding-left: 4rem;
      text-transform: uppercase;
      letter-spacing: 0.69px;
    }
  }

  .menu-stone-shapes {
    width: 75%;
    margin-top: 4rem;
    h6 {
      letter-spacing: 0.69px;
    }
    .diamond-shapes-list-wrapper {
      margin-top: 2rem;
      justify-items: start;
      img {
        height: 3rem;
        margin-bottom: 1rem;
      }
    }
  }
}
