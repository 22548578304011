@media (max-width: $navbar-collapse-breakpoint) {
  .product-details {
    .col-top {
      // images
      .product-images {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        .product-thumbnails {
          grid-row-start: 2;
          div {
            width: 6rem;
            height: 8rem;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 1rem;
            margin-right: 1rem;
            img {
              object-fit: contain;
            }
          }
        }
        .zoomed-image-wrapper {
          &.show {
            display: none !important;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .product-details {
    .col-top {
      grid-template-columns: 1fr;
    }
    .col-middle {
      grid-template-columns: 1fr;
      .product-details-accordion {
        .key-value-item-wrapper {
          h5 {
            width: 60%;
          }
        }
      }
    }
  }
}
