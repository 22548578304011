.signup{
  margin: 2rem 3rem;
  .custom-page-header {
    margin-bottom: 3rem;
  }
  h2{
    margin-bottom: 3rem;
  }
  h3{
    margin: 1rem 0 2rem;
  }
  .btn-wrapper{
    margin: 3rem 0;
    text-align: center;
  }
  h6{
    margin-bottom: 2.2rem;
    margin-top: -1rem;
  }
  .form-group{
    margin-bottom: 2.2rem;
    .checkbox{
      // margin-top: -1rem;
      // margin-bottom: 2rem;
      label{
        @extend .product-desc;
        padding-left: 27px;
        input{
          zoom: 1.3;
          // color: $black-33-54;
          margin-left: -20px;
          margin-top: 2px;
        }
        p{
          margin-bottom: 0;
        }
      }
    }
  }
  .checkbox-wrapper{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      // margin-top: 2rem;
      .form-group{
        margin-bottom: 0;
      }
  }
  .info{
    margin: 0.5rem 0;
    &:last-of-type{
      margin-bottom: 3rem;
    }
  }
}