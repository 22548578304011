@media (max-width: $navbar-collapse-breakpoint) {
  .custom-navbar {
    .nav-wrapper {
      .hamburger-icon {
        display: block;
      }
      .logo-wrapper {
        flex: 1;
        img {
          height: 4rem;
          margin: 1rem 0;
        }
      }
      .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: $black-33-38;
        z-index: 100;
        visibility: hidden;
        &.open {
          visibility: visible;
          .categories-wrapper {
            transform: translateX(0);
          }
        }
        .navbar-close-icon {
          display: block;
          position: relative;
          left: -1.4rem;
          width: 1.4rem;
          margin-bottom: 3.5rem;
        }
        .mobile-account-buttons-wrapper {
          display: block;
          text-align: center;
          margin: 3rem 0;
          button {
            padding: 1.1rem 3rem;
          }
          a {
            padding: 1.1rem 1.8rem;
          }
          span {
            display: block;
            position: relative;
            font-size: 1rem;
            text-transform: lowercase;
            margin: 1.5rem 0;
            &::before,
            &::after {
              position: absolute;
              content: "";
              width: 40%;
              height: 2px;
              background-color: $grey-f1;
              top: 50%;
            }
            &::before {
              left: 0;
            }
            &::after {
              right: 0;
            }
          }
        }
        .categories-wrapper {
          width: 40%;
          min-height: 100%;
          background-color: white;
          padding: 4rem 3rem;
          transform: translateX(-100%);
          transition: all 300ms;
        }
        .category-item {
          display: block;
          border: none;
          .glyphicon-chevron-right,
          .glyphicon-chevron-down {
            padding: 0.5rem;
            background-color: $black-10;
            margin-right: 1rem;
            border-radius: 4px;
            display: inline-block;
          }
          .category-name {
            display: block;
            padding: 1.5rem 0;
          }
          .menu-wrapper {
            position: relative;
          }
          &.open {
            .menu-wrapper {
              box-shadow: none;
            }
          }
        }
      }
      .icons-wrapper {
        .account-buttons-wrapper {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .custom-navbar {
    .nav-wrapper {
      .logo-wrapper {
        img {
          height: 3rem;
        }
      }
      .backdrop {
        .categories-wrapper {
          width: 80%;
        }
      }
      .icons-wrapper {
        a,
        span {
          margin: 0 0.5rem;
        }
      }
    }
  }
}
