.footer {
  // background-color: $black-33;
  .footer-wrapper {
    display: flex;
    border-top: 1px solid $grey-dd;
    padding: 4rem 0;
    .left {
      flex: 1;
      ul {
        list-style-type: none;
        padding-left: 0;
        li {
          // line-height: 2.4rem;
          margin-right: 5rem;
          display: inline-block;
          &:last-of-type {
            margin-right: 0;
          }
          a {
            @include font($black-33, "Muli-ExtraBold", 1.2rem, 500);
          }
        }
      }
      .logos-wrapper {
        margin-top: 3rem;
        img {
          height: 5rem;
          margin-right: 3rem;
        }
      }
    }
    .right {
      // flex: 1;
      h6 {
        @include font($black-33-54, "Muli-Regular", 1.2rem, normal);
        margin-top: 0;
        text-align: right;
      }
    }
  }
}
