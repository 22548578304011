.custom-page-header {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 1rem;
  .arrow-wrapper {
    img {
      cursor: pointer;
      width: 3rem;
    }
  }
  .header-content-wrapper {
    margin-left: 1.5rem;
    h2 {
      margin: 0;
      margin-bottom: 0.8rem;
    }
  }
}