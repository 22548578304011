// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .digital-store{
    grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}