.custom-btn {
  background-color: $black-33;
  border-radius: 2px;
  padding: 13px 15px;
  @include font(#FFFFFF, "Muli-ExtraBold", 1.2rem, bold);
  text-transform: uppercase;
  border: 2px solid $black-33;
  display: inline-block;
  color: white;

  img {
    margin-right: 0.5rem;
  }

  // for black button
  &:hover {
    // on hover
    box-shadow: 0 0 9px 0 $black-33;
    background-color: $black-33;
    color: white;
    border-color: $black-33;
  }
  &:active:focus {
    // on click
    background-color: $black-light;
    color: white;
    outline: none;
    border-color: $black-light;
  }
  &:focus {
    // after click
    background-color: $black-33;
    outline: none;
    color: white;
    border-color: $black-33;
  }


  // for inverted button
  &.custom-inverse {
    background-color: #FFFFFF;
    color: $black-33;
    border: 2px solid $black-33;
    &:hover {
      // on hover
      background-color: $black-33;
      box-shadow: none;
      color: white;
    }
    &:active {
      background-color: $black-light;
      border-color: $black-light;
    }
    &:focus {
      outline: none;
    }
  }


  // for primary button
  &.custom-primary {
    background-color: $primary;
    border-color: $primary;
    &:hover {
      // on hover
      box-shadow: 0 0 9px 0 $primary-87;
      color: white;
    }
    &:active {
      // on click
      background-color: $primary-dark;
      color: white;
      border-color: $primary-dark;
    }
    &:focus {
      // after click
      outline: none;
      color: white;
    }
  }
}