.shipping-address {
  padding-bottom: 4rem;
  h3.subtitle-desktop {
    margin-top: 3.2rem;
    margin-bottom: 1.8rem;
  }
  .address-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.6rem;
    .address-item {
      border: 1px solid $grey-e2;
      padding: 1.6rem;
      border-radius: 4px;
      min-height: 12rem;
      cursor: pointer;
      &.selected {
        border: 2px solid $primary;
        background-color: rgba(212, 177, 149, 0.2);
      }
      .top-line {
        display: flex;
        h4 {
          flex: 1;
          margin: 0;
        }
        .icon-wrapper {
          .glyphicon {
            padding: 0 0.3rem 0.3rem;
            margin-left: 0.3rem;
          }
        }
      }
      h5 {
        margin-top: 0.2rem;
        margin-bottom: 0;
      }
      h6 {
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
    .add-address-item {
      @extend .address-item;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .shipping-address-cart {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 2rem;
  }

  .amount-breakup {
    width: 300px;
    .amount-breakup-item {
      display: flex;
      padding: 1rem;
      &:last-child {
        border-top: 2px solid $grey-e5;
      }
      h6 {
        margin: 0;
        flex: 1;
        font-size: 1.5rem;
      }
      span {
        margin: 0;
      }
    }
  }

  .continue-btn {
    float: right;
    margin: 4rem 0;
    padding: 1.3rem 6.6rem;
  }
}
