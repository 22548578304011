.terms-and-conditions {
  p {
    position: relative;
    padding-left: 1.5rem;
    &::before {
      content: "-";
      position: absolute;
      left: 0;
      font-size: 2rem;
    }
    span {
      font-weight: bold;
    }
  }
}
