.about {
  padding-bottom: 10rem;
  .about-images {
    width: 100%;
    margin-bottom: 3rem;
  }
  .about-icon {
    height: 5rem;
    margin-bottom: 3rem;
  }
  .h1 {
    margin-top: 1.2rem;
    margin-bottom: 5.5rem;
  }
  .subtitle-desktop {
    margin-bottom: 1rem;
    margin-top: 8rem;
  }
  h3 {
    margin-top: 0;
  }
  .paragraph-desktop {
    margin-bottom: 6rem;
  }
  .illustration-image {
    width: 100%;
    margin-bottom: 3rem;
  }
  .pride-para {
    margin-bottom: 20rem;
  }
}
