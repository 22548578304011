.product-items-slider {

  .slide-wrapper {
    padding: 0 1.5rem;
    outline: none;
  }

  & > .slick-slider {

    /* arrows */

    & > .slick-prev {
      left: -4.5rem;
      top: 40%;
    }

    & > .slick-next {
      right: -4.5rem;
      top: 40%;
    }

   
  }


}