.product-details {
  .col-top {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 3rem;
    margin: 3rem 0;
    /* ==== PRODUCT IMAGES ===== */
    .product-images {
      display: grid;
      grid-template-columns: 60px 1fr;
      grid-gap: 5rem;

      .product-thumbnails {
        div {
          border: 2px solid $grey-e3;
          margin-bottom: 1.6rem;
          padding: 1rem;
        }
        .active {
          border-color: $primary;
        }
        img {
          width: 100%;
        }
        .video-thumbnail {
          height: 80px;
          position: relative;
          &::after {
            // play button
            content: "\25b6";
            position: absolute;
            background-color: $primary;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.6rem;
            color: white;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            padding-left: 1rem;
            padding-top: 0.3rem;
          }
        }
      }
      .product-selected {
        .video-wrapper {
          position: relative;
          cursor: pointer;
          &::after {
            // play button
            content: "\25b6";
            position: absolute;
            background-color: $black-33;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 3rem;
            color: white;
            height: 8rem;
            width: 8rem;
            padding-left: 0.4rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .image-wrapper {
          position: relative;
          border: 2px solid $grey-e3;
          background-image: linear-gradient(
            34deg,
            rgba(0, 0, 0, 0.1),
            rgba(255, 255, 255, 0.1) 58%,
            rgba(6, 6, 6, 0.1) 96%
          );
          img {
            width: 100%;
          }
          .zoomed-image-wrapper {
            position: absolute;
            top: 0;
            left: 100%;
            width: 100%;
            height: 100%;
            margin-left: 1rem;
            display: none;
            border: 2px solid $grey-e3;
            overflow: hidden;
            z-index: 3;
            background-color: $white;
            background-image: linear-gradient(
              34deg,
              rgba(0, 0, 0, 0.1),
              rgba(255, 255, 255, 0.1) 58%,
              rgba(6, 6, 6, 0.1) 96%
            );
            &.show {
              display: block;
            }
            img {
              width: 100%;
              transform: scale(2.5);
            }
          }
        }
      }
    }

    // ======== PRODUCT CUSTOMIZATIONS ============
    .product-customizations {
      h1 {
        margin-top: 1rem;
        color: #b77c4d;
      }
      h2 {
        margin-top: 1.4rem;
        margin-bottom: 1.5rem;
      }
      h3 {
        margin-top: 0;
      }
      .retail-price-text,
      .set-retail-price-link {
        @extend .product-desc;
        color: $primary;
        font-weight: bold;
      }
      .set-retail-price-link {
        text-decoration: underline;
      }
      p.product-desc {
        margin-bottom: 2rem;
      }

      .more-option {
        text-align: center;
        margin-top: 0.4rem;
        .btn-link {
          text-decoration: underline;
        }
      }
      .btn-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
        margin-top: 1rem;
      }
    }
  }

  .col-middle {
    border-top: 1px solid $grey-f1;
    padding: 2rem 0 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    .product-inclusions {
      p,
      h6 {
        margin: 2.5rem 0 2rem;
        line-height: 3rem;
        padding-left: 1.4rem;
        position: relative;
      }
      p::before {
        content: "-";
        position: absolute;
        left: 0;
      }
      h6 {
        padding-left: 0;
      }
    }
    // ========= PRODUCT ACCORDIONS ===========
    .product-details-accordion {
      .panel {
        margin-top: 2.4rem;
        .panel-title {
          @include font($black-33, "Muli-Bold", 1.3rem, bold);
          text-transform: uppercase;
          letter-spacing: 0.69px;
          a {
            display: flex;
            span {
              flex: 1;
            }
            span.glyphicon {
              flex: 0;
            }
          }
        }
        .panel-heading {
          background-color: white;
          padding: 3rem;
        }
        .panel-body {
          padding: 3rem;
        }
      }

      h4 {
        @include font($black-33-87, "Muli-Regular", 1.4rem, normal);
        line-height: 2.2rem;
        margin-bottom: 2.5rem;
        margin-top: 0;
      }
      .key-value-item-wrapper {
        display: flex;
        h5 {
          width: 40%;
          @include font($black-33-54, "Muli-Regular", 1.2rem, normal);
          padding-right: 1rem;
          margin: 1.2rem 0;
          line-height: 2rem;
        }
        h6 {
          flex: 1;
          @include font($black-33, "Muli-Bold", 1.3rem, bold);
          margin: 1.2rem 0;
          line-height: 2rem;
        }
      }
      .primary-stone-table {
        margin: 2rem 0;
        .stone-table-row {
          border-bottom: 1px solid $grey-d2;
          padding: 1rem;
          &:first-child {
            background-color: $grey-d2;
            span {
              @extend .product-code;
              text-transform: capitalize;
            }
          }
          span {
            width: 33.33%;
            display: inline-block;
            @extend .dark-desktop;
          }
        }
      }
      .measurement-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 2rem 0;
        div {
          width: 100px;
          height: 100px;
          margin: 0.5rem;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.cus-opt {
  padding: 0 6rem;
  &.page {
    padding: 0;
  }
  .glyphicon-info-sign {
    font-family: "Glyphicons Halflings" !important;
    font-size: 1.6rem !important;
    margin: 0 0.5rem;
    color: $grey-90 !important;
  }
  .size-guide-link {
    @include font($primary, "Muli-Bold", 1.3rem, bold);
    text-decoration: underline;
    display: inline-block;
    position: absolute;
    right: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    & + label {
      margin-top: 1rem;
    }
  }
  h6 {
    @extend .h3-desktop;
    margin: 0 1rem 1.5rem;
    &.page {
      text-transform: uppercase;
      @include font($black-33, "Muli-Bold", 1.2rem, bold);
      padding-bottom: 1.1rem;
      border-bottom: 1px solid $grey-f1;
      margin-bottom: 0;
      margin-top: 1rem;
    }
  }
  .select-wrapper {
    position: relative;
    label {
      @include font($black-33-54, "Muli-Bold", 1.2rem, bold);
      margin-bottom: 0;
      position: relative;
      bottom: -10px;
      z-index: 1;
      background-color: $white;
      right: -1rem;
      padding: 0.2rem 0.4rem;
    }
    .cus-opt-btn {
      .custom-select__control {
        min-height: 52px;
        border-radius: 4px;
      }
      .custom-select__menu {
        z-index: 2;
      }
    }
  }
  .checkbox {
    margin: 2.3rem 0 1.5rem;
    label {
      padding-left: 27px;
      @include font($black-33-54, "Muli-Regular", 1.2rem, normal);
      span {
        @include font($black-33-87, "Muli-Bold", 1.4rem, Bold);
      }
      input {
        zoom: 1.4;
        margin-left: -20px;
        margin-top: 0;
      }
    }
  }
  .note {
    margin-top: 1rem;
    @include font($black-33-87, "Muli-Bold", 1.5rem, Bold);
  }
  .quantity-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin: 1.2rem 0;
    span {
      margin: 1rem;
    }
    span.product-card-title {
      border: 1px solid $grey-e2;
      padding: 1.5rem;
    }
    span.glyphicon-plus,
    span.glyphicon-minus {
      color: $black-33;
      font-size: 1.4rem;
      cursor: pointer;
    }
    h5 {
      @include font($black-33-87, "Muli-Bold", 1.4rem, bold);
    }
  }
}

.cus-opt-wrapper,
.edit-cart {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  &.page {
    min-height: auto;
  }
  .bottom-button-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 2rem 3.4rem 2rem 6rem;
    align-items: center;
    margin-top: 2rem;
    span {
      @include font($grey-90, "Muli-Bold", 1.2rem, bold);
    }
  }
}

.similar-products {
  h3 {
    @extend .h2-desktop;
  }
  h6 {
    @extend .subtitle-desktop;
  }
}

.col-product-option {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 2rem;
  .metal-info {
    h4 {
    }
    span {
      border: 1px solid $grey-d8;
      padding: 9px 11px;
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
      border-radius: 4px;
      @include font($black-33, "Muli-ExtraBold", 1rem, bold);
      cursor: pointer;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }
  .color-info {
    span {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      display: inline-block;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.certificates-modal {
  .modal-body {
  }
  img {
    width: 100%;
    margin: 1rem 0;
    border: 2px solid $grey-dd;
  }
}
