.product-item-wrapper {
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-bottom: 2rem;
  &:hover {
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
    .colors-wrapper, .slick-arrow, .slick-dots {
      opacity: 1;
    }
  }

  h4 {
    @include font(#333333, "Muli-Regular", 1.2rem, normal);
    opacity: .54;
  }
  h5 {
    @include font(#333333, "Muli-Regular", 1.4rem, normal);
    line-height: 2.1rem;
    margin-top: -0.7rem;
  }
  h6{
    @include font(#333333, "Muli-ExtraBold", 1.8rem, bold);
  }

  .colors-wrapper {
    opacity: 0;
    span {
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
      margin: 1rem .3rem 2.2rem .3rem;
      &:hover {
        box-shadow: 0 0 2px rgba(0,0,0,0.1);
      }
    }
  }

  /* slider */
  .slick-slider {
    // width: 100%;
  }

  .slick-slider img {
    width: 100%;
    background-image: linear-gradient(34deg, rgba(0,0,0,0.1), rgba(255,255,255,0.1) 58%, rgba(6,6,6,0.1) 96%);
  }

  /* arrows */

  .slick-arrow {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    z-index: 1;
    opacity: 0;
  }

  .slick-prev {
    left: -12px;
  }

  .slick-next {
    right: -12px;
  }


  /* dots */

  .slick-dots {
    bottom: 10px;
    opacity: 0;
  }

  .slick-dots li button:before {
    font-size: 7px;
  }
}