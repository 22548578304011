@media (max-width: 767px) {
  .order-list {
    .order-item {
      .order-item-header {
        span {
          float: right;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .order-list {
    .order-item {
      .order-item-body {
        .custom-btn {
          float: right;
        }
      }
    }
  }
}