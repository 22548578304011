.custom-breadcrumb {
  margin: 1rem 0;

  & > *::after {
    content: ' / '
  }
  & > *:last-child::after {
    content: '';
  }

  a, span {
    letter-spacing: 0.69px;
    text-transform: capitalize;
  }

  a:hover {
    color: $black-33;
  }
}