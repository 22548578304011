.set-password-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
  .set-password-content {
    width: 30rem;
    .login-wrapper {
      padding: 1rem;
    }
  }
}