// ======== COLORS ===============

$white: #ffffff;
$black: #000000;
$black-light: #514e4e;
$green: #65c100;
$red: #d0021b;

$primary: #d4b195;
$primary-12: rgba(212, 177, 149, 0.12);
$primary-38: rgba(212, 177, 149, 0.38);
$primary-87: rgba(212, 177, 149, 0.87);
$primary-dark: #cc9d77;
$secondary: #0a222e; // USE AS BACKGROUND COLOR

$grey-1: rgba(61, 79, 87, 1);
$grey-dd: #dddddd;
$grey-d8: #d8d8d8;
$grey-f1: #f1f1f1;
$grey-e3: #e3e3e3;
$grey-e5: #e5e5e5;
$grey-e2: #e2e2e2;
$grey-90: #908f8f;
$grey-b1-54: rgba(177, 177, 177, 0.54);
$grey-d4: #d4d4d4;
$grey-d2: #d2d2d2;

$black-33: #333333;
$black-33-38: rgba(51, 51, 51, 0.38);
$black-33-54: rgba(51, 51, 51, 0.54);
$black-33-87: rgba(51, 51, 51, 0.87);
$black-33-20: rgba(51, 51, 51, 0.2);
$black-10: rgba(51, 51, 51, 0.1);

$white-54: rgba(255, 255, 255, 0.54);
$white-38: rgba(255, 255, 255, 0.38);

// ========== OTHER VARIABLES ============

$navbar-collapse-breakpoint: 1200px;
