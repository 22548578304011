.custom-navbar {
  position: relative;
  z-index: 99;
  .nav-wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey-f1;
    .hamburger-icon {
      padding: 1rem;
      display: none;
    }

    // left side logo
    .logo-wrapper {
      margin-right: 2.5rem;
      img {
        height: 6rem;
        margin: 1rem 0;
      }
    }

    // middle categories
    .backdrop {
      flex: 1;
      .mobile-account-buttons-wrapper {
        display: none;
      }
      .navbar-close-icon {
        display: none;
      }
      .category-item {
        cursor: default;
        display: inline-block;
        border-bottom: 4px solid transparent;
        .tag-name {
          cursor: pointer;
        }
        .glyphicon-chevron-right,
        .glyphicon-chevron-down {
          display: none;
        }
        .category-name {
          padding: 3rem 1rem;
          display: inline-block;
          letter-spacing: -0.43px;
          text-transform: uppercase;
          max-width: 14rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .menu-wrapper {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 99;
          background-color: white;
          width: 100%;
        }
        &.open {
          border-bottom-color: $primary;
          .menu-wrapper {
            display: block;
            box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.14);
          }
        }
      }
    }

    // right side icons
    .icons-wrapper {
      a,
      span {
        display: inline-block;
      }
      a,
      button,
      span {
        margin: 0 1rem;
        cursor: pointer;
      }
      .account-buttons-wrapper {
        display: inline-block;
        button,
        a {
          padding: 0.9rem 1.3rem;
        }
      }
      .cart-icon-wrapper {
        position: relative;
        .red-dot {
          height: 1.2rem;
          width: 1.2rem;
          border-radius: 50%;
          background-color: $red;
          position: absolute;
          right: -0.6rem;
          top: -0.3rem;
          margin: 0;
        }
      }
    }
  }
}
