.login-wrapper {
  width: 100%;
  padding: 3.5rem 6rem;
  .custom-btn {
    width: 100%;
  }
  .forgot-password {
    text-align: right;
    display: block;
    margin-top: 1.6rem;
    @include font($black-33, "Muli-Regular", 1.4rem, normal);
    cursor: pointer;
  }
  h6 {  
    margin-bottom: 2.7rem;
  }
  .signup-link {
    cursor: pointer;
    margin-left: 0.5rem;
  }
}