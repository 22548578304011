.filter-item-wrapper {
  padding: 3rem 1.5rem;
  border-top: 1px solid $grey-f1;
  height: 0;
  overflow: hidden;
  &.open {
    height: auto;
  }
  .filter-item-header {
    cursor: pointer;
    margin-bottom: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    h3 {
      @extend .dark-desktop;
      margin: 0;
      flex: 1;
    }
    .glyphicon {
      margin-left: 1rem;
    }
    p {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
  .filter-item-options {
    h5 {
      margin-top: -1rem;
      margin-bottom: 2rem;
      @include font($primary, "Muli-ExtraBold", 1.6rem, bold);
    }
    .price-range-wrapper {
      padding: 0 0.8rem;
    }
    .price-range {
      .rc-slider-rail {
        background-color: $grey-f1;
        border-radius: 0;
      }
      .rc-slider-track {
        background-color: $primary;
        border-radius: unset;
      }
      .rc-slider-handle {
        background-color: $primary;
        border-color: $primary;
        &:active,
        &:focus,
        &:hover {
          border-color: $primary;
        }
        box-shadow: none;
        width: 20px;
        height: 20px;
        margin-top: -9.5px;
      }
    }
    .checkbox {
      margin: 0;
      margin-bottom: 1.5rem;
      label {
        @extend .light-desktop;
        padding-left: 27px;
        input {
          zoom: 1.4;
          margin-left: -20px;
          margin-top: 0;
        }
      }
    }
  }
}

.filter-main-wrapper {
  background-color: rgba(154, 154, 154, 0.05);
  padding-top: 2rem;
}

.filter-wrapper {
  .filter-title {
    @extend .dark-desktop;
    font-size: 1.6rem;
    padding: 0 1.5rem 3rem;
    text-transform: uppercase;
    .btn {
      float: right;
      padding: 0;
      color: $primary;
      text-decoration: none;
      &:focus,
      &:active:focus {
        outline: none;
      }
    }
  }
}

.mobile-filter-wrapper {
  position: fixed;
  z-index: 100;
  background-color: $white;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  top: 0;
  .mobile-filter {
    padding: 2rem 1.5rem 8rem;
    .filter-title {
      @extend .dark-desktop;
      font-size: 1.6rem;
      padding: 0 0rem 2rem;
      text-transform: uppercase;
      .back-icon {
        width: 2.8rem;
        margin-right: 1rem;
        margin-top: -2px;
        cursor: pointer;
      }
      .btn {
        float: right;
        padding: 0;
        color: $primary;
        text-decoration: none;
        &:focus,
        &:active:focus {
          outline: none;
        }
      }
    }
    .sort-item-wrapper {
      ul {
        padding-left: 0;
        list-style-type: none;
        li {
          border-bottom: 1px solid $grey-f1;
          .btn {
            border: 0;
            padding: 1.4rem 2rem;
            font-size: 1.3rem;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
