// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .custom-container{
    // padding: 4rem 2rem;
    .breadcrumb-wrapper{
      flex: 0.25;
    }
    .sort-wrapper{
      flex: 0.75;
      padding-left: 0;
      .left{
        flex: 0.75;
      }
      .right{
        flex: 0.25;
      }
    }
    .content-wrapper{
      .filter-wrapper{
        flex: 0.25;
        // border: 0.1px solid #f5f5f5;
      }
      .main{
        flex: 0.75;
        padding-left: 0;
        .product-list{
          grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .custom-container{
    padding: 4rem 2rem;
    .breadcrumb-wrapper{
      flex: 0.30;
    }
    .sort-wrapper{
      flex: 0.70;
      padding-left: 0;
      .left{
        flex: 0.70;
      }
      .right{
        flex: 0.30;
      }
    }
    .content-wrapper{
      .filter-wrapper{
        flex: 0.30;
        // border: 0.1px solid #f5f5f5;
      }
      .main{
        flex: 0.70;
        padding-left: 0;
        .product-list{
          grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
        }
        .empty-state-wrapper img{
          width: 24rem;
        }
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .mobile-btns{
    display: grid;
    // grid-auto-columns: 1fr 1fr;
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    column-gap: 1.4rem;
  }
  .custom-container{
    padding: 4rem 1.5rem;
    .content-wrapper{
      .main{
        flex: 1;
        padding-left: 0;
        .product-list{
          grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
        }
        .empty-state-wrapper img{
          width: 24rem;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}
