@media (max-width: 1200px) {
  .side-drawer {
    .content-wrapper {
      width: 40%;
    }
  }
}

@media (max-width: 991px) {
  .side-drawer {
    .content-wrapper {
      width: 50%;
    }
  }
}


@media (max-width: 576px) {
  .side-drawer {
    .content-wrapper {
      width: 100%;
      padding-top: 4rem;
      .back-icon {
        top: 4rem;
        left: 1rem;
      }
      h4.title {
        margin-bottom: 2rem;
        margin-left: 5rem;
      }
    }
  }
}