@media (min-width: 1850px) {
  .slick-slider.banner {
    .slick-slide {
      .background-wrapper {
        // padding-top: 16%;
        .slide-content {
          // margin: 200px 10%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .slick-slider.banner {
    .slick-slide {
      .background-wrapper {
        padding-top: 100%;
        .slide-content {
          width: 90%;
          left: 5%;
        }
      }
    }
  }

  .diamond-shapes {
    .container {
      padding: 2rem;
      padding-bottom: 0;
      margin: 0 1.5rem;
    }
  }
}
