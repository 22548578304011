.empty-state-wrapper {
  margin: 5rem 0;
  text-align: center;
  img{
    width: 24rem;
  }
  h5 {
    margin-top: 2.5rem;
  }
  h6 {
    margin-top: 1.4rem;
  }
  button {
    padding: 1.2rem 4rem;
    margin-top: 1rem;
  }
}