@media (max-width: 991px) {
  .order-details .order-details-content {
    .order-summary {
      .order-summary-item {
        width: 33.33%;
      }
    }
  }
}


@media (max-width: 768px) {
  .order-details .order-details-content {
    h5 {
      margin-bottom: 0.5rem;
    }
    h6 {
      margin-top: 0.5rem;
    }
    .fulfillment-details {
      .timeline-wrapper {
        flex-direction: column;
        margin: 2rem;
        .timeline-item {
          min-height: 7rem;
          text-align: left;
          padding-left: 2rem;
          h4 {
            margin-top: 0;
          }
          &::before {
            height: 100%;
            width: 2px;
            left: 0;
            top: -100%;
            transform: translateY(10px);
          }
          &::after {
            left: 0;
            top: 0;
            transform: translate(-3px, 3px);
          }
        }
      }
    }
  }
}


@media (max-width: 576px) {
  .order-details .order-details-content {
    .order-summary {
      .order-summary-item {
        width: 50%;
      }
    }
  }
}